import React, { useEffect } from 'react'
import classNames from 'classnames'

// Styles & Images
import 'src/components/button/WatchVideo/scss/style.scss'

// Partials:
const Wrapper = ({ children, size, active, disabled, href }) => {
  const props = {
    className: classNames('x__button-play', `x__button-play--${size}`, {
      'x__button-play--active': active,
      'x__button-play--disabled': disabled,
    }),
    tabIndex: 0,
    disabled,
    href,
    children,
  }

  return <a {...props} data-fslightbox />
}

const Icon = ({ className }) => (
  <i className={className}>
    <svg viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_901_580)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68 50C59.7157 50 53 56.7157 53 65C53 73.2843 59.7157 80 68 80C76.2843 80 83 73.2843 83 65C83 56.7157 76.2843 50 68 50ZM74.1611 65.9038C74.6311 65.6101 74.6311 64.9256 74.1611 64.6318L65.6119 59.2886C65.1124 58.9764 64.4644 59.3355 64.4644 59.9246V70.6111C64.4644 71.2002 65.1124 71.5593 65.6119 71.2471L74.1611 65.9038Z"
          fill="url(#paint0_linear_901_580)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_901_580"
          x="0.5"
          y="0.5"
          width="135"
          height="135"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="26.25" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_901_580" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_901_580"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_901_580"
          x1="68"
          y1="50"
          x2="68"
          y2="80"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#E6E6E6" />
        </linearGradient>
      </defs>
    </svg>
  </i>
)

const Copy = ({ data }) => (
  <span className="x__copy">
    <Icon className="x__copy__icon-left" />
    <span>{data}</span>
  </span>
)

// Main Component:
const WatchVideoButton = ({ copy = 'Watch Video', size = 'larger', active, disabled, href }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      import('src/plugins/fslightbox.js')
    }
  }, [])

  const props = { size, active, disabled, href }

  return (
    <Wrapper {...props}>
      <Copy data={copy} />
    </Wrapper>
  )
}

export default WatchVideoButton
